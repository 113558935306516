body {
  font-size: 1em;
  @include media(">=desktop") {
    // font-size: 1.1em;
  }
  @include media(">=hd") {
    font-size: 1.2em;
  }
  @include media(">=realhd") {
    font-size: 1.3em;
  }
  @include media(">=4k") {
    font-size: 1.4em;
  }
}
:root {
  --swiper-navigation-size: 2.5em !important;
  --swiper-pagination-bullet-size: 0.6em !important;
  --swiper-pagination-bullet-horizontal-gap: 0.2em !important;
  @include media(">=desktop") {
    --swiper-navigation-size: 3em !important;
    --swiper-pagination-bullet-size: 0.8em !important;
  }
}