section.show {
  margin: 2em 0;
  > .inside {
    display: flex;
    nav {
      display: block;
      position: sticky;
      top: 0;
      min-width: 10em;
      text-align: end;
      // font-size: 0.85em;
      ul {
        list-style: none;
        li {
          padding: 0.3em 0;
          a {
            color: inherit;
            position: relative;
            cursor: pointer;
            text-decoration: none;
            &:hover {
              font-weight: 700;
            }
          }
          &.active {
            font-weight: 700;
            a:after {
              position: absolute;
              content: "";
              width: 100%;
              top: 100%;
              left: 0;
              background: $gradient;
              height: 2px;
            }
          }
        }
      }
    }
    hr {
      border: 1px solid $border;
      border-width: 0;
      border-left-width: 1px;
      margin: 0 1.6em;
      height: initial;
    }
    .content {
      flex-grow: 1;
      img {
        width: 100%;
      }
      .subtitle {
        font-size: 1.3em;
      }
      hr {
        border: 1px solid $border;
        border-width: 0;
        border-bottom-width: 1px;
        margin: 1.6em 0;
      }
      .pictures {
        display: grid;
        gap: 2em;
        grid-template-columns: repeat(2, 1fr);
        .picture {
          .inside {
            position: relative;
            img {
                min-height: 4.6em;
              background: #eee;
              display: block;
            }

            .links {
              position: absolute;
              text-align: left;
              left: 0;
              bottom: 0;
              padding: 1em;
              display: flex;
              gap: 0.7em;
              a {
                display: inline-block;
                padding: 0.7em 1em;
                color: $black;
                background-color: $white;
                text-decoration: none;
                font-weight: 700;
                &:hover {
                  background: $gradient;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}
