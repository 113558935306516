// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

button,
.button {
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
  background: none;
  border: 0;
  text-align: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  position: relative;
  outline: none;

  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  > .inside {
    display: block;
    position: relative;
    border-radius: 3em;
    padding: 1em 2.1em;
    background: $gradient;
    color: $black;
    font-weight: bold;
  }

  &.fullwidth {
    display: block;
    width: 100%;
  }

  &.circle {
    height: 5em;
    width: 5em;
    margin: 0 0.2em;
    & > .inside {
      padding: 0;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 100%;
    }
  }

  // &.red {
  //   & > .inside,
  //   &:before {
  //     background: $red;
  //     color: $white;
  //   }
  //   &:hover {
  //     > .inside {
  //       // background: $darkred;
  //     }
  //   }
  // }

  // &.light-purple {
  //   & > .inside,
  //   &:before {
  //     background: $light-purple;
  //     color: $white;
  //   }
  //   &:hover {
  //     > .inside {
  //       // background: $darkred;
  //     }
  //   }
  // }

  &.color-white {
    > .inside {
    }
  }

  &.shadow {
    > .inside {
      transition: transform 90ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &:before {
      transition: transform 90ms cubic-bezier(0.165, 0.84, 0.44, 1);
      border-radius: 3em;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: block;
      z-index: -1;
      transform: translateY(0.3em) rotate(1.5deg);
      opacity: 0.3;
    }
    @include media(">=desktop") {
      &:hover {
        > .inside {
          transform: translateY(-0.1em);
        }
        &:before {
          transform: translateY(0.3em) rotate(1.5deg);
        }
      }
    }
    &:active {
      > .inside {
        transform: translateY(0.3em);
      }
      &:before {
        transform: translateY(0.4em) rotate(0deg);
      }
    }
  }
}
