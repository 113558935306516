input,
input[text],
input[email],
textarea {
  font-size: 0.9em;
  font-family: $font, arial, sans-serif;
  line-height: 1.3;
  display: block;
  width: 100%;
  padding: 1em 2em;
  border-style: solid;
  border-radius: 10em;
  &.hollow {
    background: transparent;
    &.white {
      border-color: rgba(255, 255, 255, 0.3);
      color: white;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 0.4;
      }
      &:hover {
        border-color: rgba(255, 255, 255, 0.4);
      }
      &:focus {
        border-color: rgba(255, 255, 255, 0.9);
        outline: none;
      }
    }
  }
}
.label {
  margin-bottom: 0.5em;
}
