.wrap {
  width: 85%;
  margin: 0 auto;
  max-width: 1500px;
}

.smallwrap {
  width: 80%;
  margin: 0 auto;
  max-width: 700px;
}

.bigwrap {
  width: 80%;
  margin: 0 auto;
  max-width: 1400px;
}

.blogwrap {
  width: 96%;
  margin: 0 auto;
  max-width: 740px;
  font-size: 1.25em;
  line-height: 1.8em;
}

.cutewrap {
  width: 80%;
  margin: 0 auto;
  max-width: 480px;
  line-height: 2.2em;
  //   @include media(">desktop") {
  //     font-size: 1.25em;
  //   }
}
