.videos {
  margin: 0 3em;
  .video {
    display: inline-block;
    padding: 1.5em;
    max-width: 100%;
    width: 25em;
    @include media(">=desktop") {
      width: 20em;
    }
    @include media(">=hd") {
      width: 25em;
    }
    @include media(">=realhd") {
      width: 33em;
    }

    @include media(">=4k") {
      width: 38em;
    }

    h5 {
      margin: 0 0 0.5em;
    }
  }
}
