table.pretty-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  thead {
    tr {
      td {
        font-weight: 700;
        border-bottom: 1px solid;
      }
    }
  }
  tr {
    td {
        padding: 0.3em 0.5em;
        border-bottom: 1px solid $light-gray;
    }
  }
}
