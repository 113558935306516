.video-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.3%;
  video,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
