p, q {
  margin: 0 0 0.7em;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0.7em 0 0.3em;
  font-weight: 700;
  line-height: 1.15;
}

h1 {
  margin-top: 0;
  font-size: 5.653em;
}

h2 {
  font-size: 1.6em;
  @include media(">=desktop") {
    font-size: 2.2em;
  }
  @include media(">=realhd") {
    font-size: 3em;
  }
}

h3 {
  font-size: 2.827em;
  font-size: 2.95em;
  letter-spacing: -0.01em;
  line-height: 1.05;
}

h4 {
  font-size: 1.999em;
}

h5 {
  margin: 1.4em 0 0.5em;
  font-size: 1.414em;
}

small,
.text_small {
  font-size: 0.707em;
}

.normal-text-size {
  // font-size: 1.2em;
}


q {
  display: block;
  font-style: italic;
  font-size: 1.3em;
}