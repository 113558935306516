.colorful-section {
  //   @for $i from 0 through 10 {
  //     &:nth-child(#{$i}) {
  //       transition-delay: $initial_wait + $delay_between_each * $i;
  //       animation-delay: $initial_wait + $delay_between_each * $i;
  //     }
  //   }

  padding: 3em 0;
  .icon {
    margin-bottom: 1em;
  }

  h3 {
      margin-top: 0;
  }

  // &.cs-1 {
  //   background: $section-1-bgc;
  //   h3 {
  //     color: $section-1-title-color;
  //   }
  //   color: $section-1-text-color;
  //   .icon {
  //     width: 9.5em;
  //   }
  // }

  // &.cs-2 {
  //   background: $section-2-bgc;
  //   h3 {
  //     color: $section-2-title-color;
  //   }
  //   color: $section-2-text-color;
  //   .icon {
  //     width: 7em;
  //   }
  // }

  // &.cs-3 {
  //   background: $section-3-bgc;
  //   h3 {
  //     color: $section-3-title-color;
  //   }
  //   color: $section-3-text-color;
  //   .icon {
  //     width: 5em;
  //   }
  // }

  // &.cs-4 {
  //   background: $section-4-bgc;
  //   h3 {
  //     color: $section-4-title-color;
  //   }
  //   color: $section-4-text-color;
  //   .icon {
  //     margin: 0.5em 0 0;
  //     width: 8em;
  //   }
  // }
}
